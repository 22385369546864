import React, { Component } from 'react';

class TasksForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: '',
            responsible: '',
            description: '',
            priority: 'low'
        }
        this.handleInput = this.handleInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }



    handleInput(e) {
        const { value, name } = e.target;
        this.setState({
            [name]: value
        })
        //console.log(e.target.value, e.target.name)
        //console.log(this.state)
    }

    handleSubmit(e) {
        e.preventDefault();
        console.log(this.state);
        this.props.onAddTask(this.state)
    }

    render() {
        return (
            <div className="col-md-4 my-2">
                <div className="card">
                    <form className="card-body" onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <input
                                type="text"
                                name="title"
                                onChange={this.handleInput}
                                className="form-control"
                                placeholder="titulo" />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                name="responsible"
                                onChange={this.handleInput}
                                className="form-control"
                                placeholder="Responsable" />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                name="description"
                                onChange={this.handleInput}
                                className="form-control"
                                placeholder="Descripción" />
                        </div>
                        <div className="form-group">
                            <select
                                name="priority"
                                className="form-control"
                                onChange={this.handleInput}
                                placeholder="Prioridad">
                                <option>low</option>
                                <option>medium</option>
                                <option>high</option>
                            </select>
                        </div>
                        <div>
                            <button type="submit" className="btn btn-primary">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}


export default TasksForm;