import React, { Component } from 'react';
import './App.css';
import { todos } from './fakeBBDD/todos.json'
import Navegacion from './components/Navegacion'
import TasksForm from './components/TasksForm';


console.log(todos)

class App extends Component {

  constructor() {
    super();
    this.state = {
      todos: todos //se puede abreviar a solo todos por la sintaxis es6
    }
    this.handleAddTasks = this.handleAddTasks.bind(this)
  }


  handleAddTasks(task) {
    this.setState({
      todos: [...this.state.todos, task]
    })
  }

  removeTask(index) {
    if (window.confirm('¿Deseas eliminar la tarea?')) {
      this.setState({
        todos: this.state.todos.filter((e, i) => {
          return i !== index
        })
      })
    }
  }

  render() {

    const datos = this.state.todos.map((tarea, i) => {


      return (
        <div className="col-md-4 my-2" key={i}>
          <div className="card">
            <div className="card-header">
              <h3>{tarea.title}</h3>
              <span className="badge badge-pill badge-danger">
                {tarea.priority}
              </span>
            </div>
            <div className="card-body">
              <p>{tarea.description}</p>
              <p><b>{tarea.responsible}</b></p>
            </div>
            <div className="card-footer">
              <button className="btn btn-danger" onClick={this.removeTask.bind(this, i)}>
                ELIMINAR
              </button>
            </div>
          </div>
        </div>
      )
    })



    return (
      <div className="app">
        <header>
          <Navegacion titulo="Simple Task Manager" cantidad={datos.length}></Navegacion>
        </header>
        <main className="container">
          <div className="row my-2">
            <TasksForm onAddTask={this.handleAddTasks}></TasksForm>
            {datos}
          </div>
        </main>
        <footer className="footerPrincipal">
          <Navegacion></Navegacion>
        </footer>

      </div>
    )
  }

}
export default App;
