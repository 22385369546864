import React, { Component } from 'react';

class Navegacion extends Component {
    render() {
        if (this.props.titulo === undefined) {
            return (
                <nav className="navbar navbar-dark bg-dark">
                    <a href="http://simpletaskmanager.juanpabload.com/" className="text-white">
                        Navegación
                </a>
                </nav>)
        }
        else {
            return (
                <nav className="navbar navbar-dark bg-dark navprincipal">
                    <a href="http://simpletaskmanager.juanpabload.com/" className="text-white">
                        {this.props.titulo}
                    </a>
                    <p>Numero de tareas: <span className="badge badge-pill badge-light">
                        {this.props.cantidad}
                    </span></p>

                </nav>
            )
        }
    }
}

export default Navegacion;